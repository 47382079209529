import React from 'react'
import {
    FaFacebook,
    FaInstagram,
    FaWhatsapp,
    FaTwitter,
    FaYoutube,
    FaLinkedin
} from 'react-icons/fa';
import {
    FooterContainer,
    FooterWrap,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    SocialIcons,
    SocialIconLink
} from './FooterElements';
const Footer = () => {
  return (
    <FooterContainer>
        <FooterWrap>
            <SocialMedia>
                <SocialMediaWrap>
                    <SocialLogo to="/">© TikTok Food</SocialLogo>
                    <SocialIcons>
                        <SocialIconLink href="https://facebook.com/TiktokFoodOfficial" target="_blank" aria-label="Facebook" rel="noopener noreferrer">
                            <FaFacebook />
                        </SocialIconLink>
                        <SocialIconLink href="https://www.instagram.com/TiktokFoodOfficial/" target="_blank" aria-label="Instagram" rel="noopener noreferrer">
                            <FaInstagram />
                        </SocialIconLink>
                        <SocialIconLink href="https://www.linkedin.com/company/tiktokfoodofficial/" target="_blank" aria-label="LinkedIn" rel="noopener noreferrer">
                            <FaLinkedin />
                        </SocialIconLink>
                        <SocialIconLink href="https://api.whatsapp.com/send?phone=917696880011" target="_blank" aria-label="WhatsApp" rel="noopener noreferrer">
                            <FaWhatsapp />
                        </SocialIconLink>
                    </SocialIcons>
                </SocialMediaWrap>
            </SocialMedia>        
        </FooterWrap>
    </FooterContainer>
  )
}

export default Footer
