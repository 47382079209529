import React from 'react';
import {
    ProductsContainer,
    ProductsHeading,
    ProductWrapper,
    ProductCard,
    ProductImg,
    ProductInfo,
    ProductTitle,
    ProductDesc,
    ProductPrice,
    ProductButton,    
    SocialIconLink
} from './ConnectElements';

const Connects = ({heading, data}) => {
  return (
    <ProductsContainer>
        <ProductsHeading>{heading}</ProductsHeading>
        <ProductWrapper>
            {data.map((product, index) => {
                return (
                    <ProductCard key={index}>
                        <ProductImg src={product.img} alt={product.alt} />
                        <ProductInfo>
                            <ProductTitle>{product.name}</ProductTitle>
                            <ProductDesc>{product.desc}</ProductDesc>
                            <ProductPrice>{product.price}</ProductPrice>
                            <SocialIconLink href={product.link} target="_blank" aria-label="Facebook" rel="noopener noreferrer">
                            <ProductButton>{product.button}</ProductButton>
                            </SocialIconLink>
                            
                        </ProductInfo>
                    </ProductCard>
                );
            })
            }
        </ProductWrapper>
    </ProductsContainer>      
  );
};

export default Connects;
