import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {GlobalStyle} from './globalStyles';
import Hero from './components/Hero';
import Products from './components/Products';
import { productData, productData2 } from './components/Products/data';
import { socialData } from './components/Connect/data';
import Feature from './components/Feature';
import Footer from './components/Footer';
import Connects from './components/Connect';


function App() {
  return (
    <Router>
      <GlobalStyle />
        
      <Hero/>
      <Products heading='Choose your favorite' data={productData} />
      <Feature/> 
      {/* <Products heading='Excited! Join us' data={productData2} /> */}
      <Connects heading='Follow us for all the latest happenings' data={socialData} />
      <Footer/> 
    </Router>
    //<h1>TikTok Food</h1>
  );
}

export default App;
