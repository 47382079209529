import React from 'react'
import {Nav, NavIcon, NavLink, NavLogo, Bars} from './NavbarElements';
import siteLogo from '../../images/tiktokfood.png';

const Navbar = ({toggle}) => {
  return (
    // <div>
    //   Hello
    // </div>
    <>
      <Nav>
        <NavLink to="/"><img alt="TikTok Food" src={siteLogo} /></NavLink>
        <NavIcon onClick={toggle}> 
          {/* <p>Menu</p> */}
          <Bars/>
        </NavIcon>
      </Nav>
    </>
  )
}

export default Navbar;
