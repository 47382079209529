import React from 'react'
import {FeatureContainer, FeatureButton} from './FeatureElements';

const Feature = () => {
  return (
    <FeatureContainer>
        <h1>Pizza of the Day</h1>
        <p>
            Veg cryspy pizza topped with capsicum, mushroom and lots of love.
        </p>
        {/* <FeatureButton>Order Now</FeatureButton> */}
    </FeatureContainer>
  )
}

export default Feature
