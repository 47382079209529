import fb from '../../images/connect/1.jpg'
import insta from '../../images/connect/2.jpg'
import wp from '../../images/connect/3.jpg'
import linkedIn from '../../images/connect/1.jpg'

export const socialData = [
    {
        img: fb,
        alt: 'Facebook',
        name: '@TikTokFoodOfficial',
        desc: 'Follow us on Facebook',
        link: 'https://facebook.com/TiktokFoodOfficial',
        button: 'Explore'
    },
    {
        img: insta,
        alt: 'Instagram',
        name: '@TikTokFoodOfficial',
        desc: 'Follow us on Instagram',
        link: 'https://www.instagram.com/TiktokFoodOfficial/',
        button: 'Explore'
    },
    {
        img: wp,
        alt: 'Whatsapp',
        name: '@TikTokFoodOfficial',
        desc: 'Message us on Whatsapp',
        link: 'https://api.whatsapp.com/send?phone=917696880011',
        button: 'Chat Now'
    }
];