import React from 'react'
import {
    SidebarContainer, 
    Icon, 
    CloseIcon, 
    SidebarMenu, 
    SidebarLink, 
    SidebarRoute, 
    SideBtnWrap 
} from './SidebarElements'

const Sidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen = {isOpen} onClick = {toggle}>
        <Icon onClick={toggle}>
            <CloseIcon/>
        </Icon>
        <SidebarMenu>
            <SidebarLink to="/">Pizzas</SidebarLink>
            <SidebarLink to="/">Burgers</SidebarLink>
            <SidebarLink to="/">Candy</SidebarLink>            
        </SidebarMenu>
        <SideBtnWrap>
            {/* <SidebarRoute to="/">Order Now</SidebarRoute> */}
        </SideBtnWrap>
    </SidebarContainer>
  )
}

export default Sidebar
