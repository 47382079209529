import style from 'styled-components';
import {NavLink as Link} from 'react-router-dom';
import styled from 'styled-components';
import {FaBars} from 'react-icons/fa';
import siteLogo from '../../images/tiktokfood.png';

export const Nav = style.nav`
background: transparent;
height: 80px;
display: flex;
justify-conent: center;
font-weight: 700;
`;

export const NavLink = style(Link)`
color: #000;
font-size: 2rem;
display: flex;
align-items: center;
text-decoration: none;
cursor: pointer;
margin-left: 25px;

@media screen and (max-width: 400px) {
    position: absolute;
    top: 10px;
    left: 25px;
}
`;

export const NavLogo = style(Image)`
src: url(${siteLogo});
`;

export const NavIcon = styled.div`
display: block;
position: absolute;
top: 0;
right: 0;
cursor: pointer;
color: #000;
margin-right: 2rem;

p {
    transform: translate{-175%, 100%};
    font-weight: bold;    
}
`;

export const Bars = styled(FaBars)`
    margin-top: 20px;
    font-size: 2rem;
    box-shadow: 5px 5px #000;
    color:#fff;
    transform: translate(-50%, -15%);
`;


