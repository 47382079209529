import product1 from '../../images/product-1.jpg';
import product2 from '../../images/product-2.jpg';
import product3 from '../../images/product-3.jpg';

import steamed from '../../images/menu/steamed.jpg';
import salad from '../../images/menu/salad.jpg';
import burger from '../../images/menu/burger.jpg';
import wrap from '../../images/menu/wrap.jpg';
import shakes from '../../images/menu/shakes.jpg';
import icecreams from '../../images/menu/icecreams.jpg';

export const productData = [
    {
        img: steamed,
        alt: 'Steamed Food',
        name: 'Steamed Food',
        desc: 'Add health to your diet',
        price: '25+',
        button: 'Explore More'
    },
    {
        img: salad,
        alt: 'Salads',
        name: 'Salads',
        desc: 'Go Green, Eat Green',
        price: '10+',
        button: 'Explore More'
    },
    {
        img: burger,
        alt: 'Burger',
        name: 'Burger',
        desc: 'Get your very own bite',
        price: '30+',
        button: 'Explore More'
    },
    {
        img: wrap,
        alt: 'Wraps',
        name: 'Wraps',
        desc: 'Feel the Exotic flavors',
        price: '15+',
        button: 'Explore More'
    },
    {
        img: shakes,
        alt: 'Shakes',
        name: 'Shakes',
        desc: 'Beat the heat',
        price: '20+',
        button: 'Explore More'
    },
    {
        img: icecreams,
        alt: 'Ice Creams',
        name: 'Ice Creams',
        desc: "Who doesn't love it",
        price: '10+',
        button: 'Explore More'
    }
];

export const productData2 = [
    {
        img: product1,
        alt: 'Pizza',
        name: 'Supreme Pizza1',
        desc: 'Marinara sauce, basil, italian sausage, rona tomatoes, and pesto',
        price: '$19.99',
        button: 'Add to Cart'
    },
    {
        img: product2,
        alt: 'Pizza',
        name: 'Supreme Pizza',
        desc: 'Marinara sauce, basil, italian sausage, rona tomatoes, and pesto',
        price: '$19.99',
        button: 'Add to Cart'
    },
    {
        img: product3,
        alt: 'Pizza',
        name: 'Supreme Pizza',
        desc: 'Marinara sauce, basil, italian sausage, rona tomatoes, and pesto',
        price: '$19.99',
        button: 'Add to Cart'
    }
];